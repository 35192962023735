<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" lg="3" class="d-flex justify-start">
                <v-btn block rounded @click="refresh" :loading="loading">
                    <v-icon>mdi-refresh</v-icon> &nbsp;
                    refresh
                </v-btn>
            </v-col>
            <v-col cols="12" offset-lg="6" lg="3" class="d-flex justify-end">
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block rounded v-bind="attrs" v-on="on">Top-up account</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="d-flex justify-center">
                            <p>TOP-UP Account</p>
                        </v-card-title>
                        <v-card-text class="mt-5">
                            <v-form>
                                <v-select rounded solo dense v-model="topUpRequest.merchantCode" clearable
                                    label="Merchants" :items="merchants"></v-select>
                                <v-select rounded solo dense v-model="topUpRequest.account" clearable label="Wallets"
                                    :items="wallets"></v-select>
                                <v-text-field rounded solo v-model="topUpRequest.amount" dense clearable label="Amount">
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn text color="success" @click="topup" :loading="loadingTopUp">Top-up</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="dialog = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="balances" :search="search">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="search" label="Search a merchant" clearable
                                    class="mx-4 pt-4" append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-snackbar v-model="snackbar" top>
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>import { mapActions, mapState } from "vuex"

import services from "../services/services"


export default {
    data: () => ({
        dialog: false,
        loadingTopUp: false,
        wallets: ["Airtel CDF", "Airtel USD", "Vodacom USD", "Vodacom CDF", "Orange CDF", "Orange USD"],
        balances: [],
        merchants: [],
        search: "",
        loading: false,
        snackbar: false,
        text : "topup successful",
        headers: [
            { text: "Merchants", value: "merchant_code", align: "center" },
            { text: "Vodacom USD", value: "vodacom_usd", filterable: false, align: "center" },
            { text: "Vodacom CDF", value: "vodacom_cdf", filterable: false, align: "center" },
            { text: "Airtel USD", value: "airtel_usd", filterable: false, align: "center" },
            { text: "Airtel CDF", value: "airtel_cdf", filterable: false, align: "center" },
            { text: "Orange USD", value: "orange_usd", filterable: false, align: "center" },
            { text: "Orange CDF", value: "orange_cdf", filterable: false, align: "center" }
        ],
        topUpRequest: {
            account: "",
            merchantCode: "",
            amount: 0,
        }
    }),
    methods: {
        ...mapActions(["setInstutionMerchants"]),
        async topup() {
            this.loadingTopUp = true

            const response = await services.topup(this.topUpRequest)

            if (response === true) {
                this.snackbar = true
                this.text = "topup successful"
            }
            else {
                this.snackbar = true
                this.text = "topup failed"
            }
            this.dialog = false
            this.loadingTopUp = false
        },
        async refresh() {
            this.loading = true
            const credits = await services.get_credit_balances()
            this.balances = credits.credit_balance;
            this.loading = false
        }
    },
    computed: {
        ...mapState(['institutionMerchants'])
    },
    async mounted() {
        const credits = await services.get_credit_balances()
        this.balances = credits.credit_balance;

        await this.setInstutionMerchants()

        this.$nextTick(() => {
            for (let i = 0; i < this.institutionMerchants.length; i++) {
                this.merchants.push(this.institutionMerchants[i][0]);
            }
        })
    }
}
</script>


<style scoped>
</style>